.clients-inner {
  display: flex;
  justify-content: space-around;
  position: relative;
  padding: 15px 67px 0px;
  margin-bottom: 150px;
  @include media("<=desktop") {
    padding: 15px 57px 0px;
  }
  @include media("<=tablet") {
    padding: 15px 30px 0;
    margin-bottom: 50px;
  }
  @include media("<=450px") {
    flex-direction: column;
    padding: 15px 15px 0;
    margin-bottom: 30px;
  }
}

.clients-button {
  position: absolute;
  width: 55px;
  height: 55px;
  background-color: black;
  padding: 13px;
  border: 0;
  cursor: pointer;
  &__left {
    top: 60px;
    left: 0;
  }
  &__right {
    bottom: 60px;
    right: 0;
  }
  @include media("<=tablet") {
    width: 30px;
    height: 30px;
    padding: 8px;
  }
  @include media("<=450px") {
    display: none;
  }
}

.button-triangle {
  border: 0 solid transparent;
  border-left-width: 15px;
  border-right-width: 15px;
  border-top: 15px solid #fff;
  @include media("<=tablet") {
    border-left-width: 7px;
    border-right-width: 7px;
    border-top: 7px solid #fff;
  }
}

.clients-item {
  width: 33%;
  max-width: 360px;
  min-height: 451px;
  border: 13px solid #000000;
  &__white {
    background-color: #fff;
  }
  &__blue {
    background-color: #00ccff;
  }
  &__yellow {
    background-color: #ffc600;
  }
  @include media("<=desktop") {
    max-width: 300px;
  }
  @include media("<=tablet") {
    min-height: auto;
    max-width: 230px;
    border: 8px solid #000000;
  }
  @include media("<=450px") {
    width: 100%;
    max-width: 400px;
    margin: 0 auto 20px;
  }
}

.clients-title {
  position: relative;
  border: 0 solid transparent;
  border-left-width: 60px;
  border-right-width: 60px;
  border-top: 85px solid #000;
  color: #fff;
  text-align: center;
  @include media("<=tablet") {
    border-top: 60px solid #000;
  }
}

.clients-name {
  position: absolute;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  top: -75px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
  @include media("<=tablet") {
    font-size: 14px;
    line-height: 20px;
    top: -55px;
    width: 90px;
  }
  @include media("<=450px") {
    width: 150px;
  }
}

.clients-position {
  position: absolute;
  font-size: 17px;
  line-height: 24px;
  top: -45px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
  @include media("<=tablet") {
    font-size: 12px;
    line-height: 12px;
    top: -30px;
    width: 95px;
  }
  @include media("<=450px") {
    width: 160px;
  }
}

.clients-text {
  font-size: 17px;
  font-weight: 400;
  font-style: italic;
  line-height: 25px;
  padding: 26px 54px 10px 54px;
  span {
    font-family: "Mont-Black";
    font-size: 29px;
  }
  @include media("<=tablet") {
    font-size: 14px;
    line-height: 18px;
    padding: 26px 26px 20px;
  }
  @include media("<=450px") {
    line-height: 16px;
  }
}
