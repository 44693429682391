.services-inner {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @include media("<=desktop") {
    padding: 0 15px;
  }
  @include media("<=450px") {
    display: block;
    padding: 0 15px;
    margin-bottom: 20px;
  }
}

.section-title {
  padding: 149px 0 0 67px;
  margin-bottom: 65px;
  color: #000000;
  font-size: 69px;
  font-weight: 900;
  text-transform: uppercase;
  &::after {
    content: "";
    display: inline-block;
    width: 27px;
    height: 27px;
    background-color: #e911a0;
    border-radius: 50%;
    margin-left: 15px;
    margin-bottom: 12px;
    @include media(">414px", "<=tablet") {
      margin-bottom: 2px;
      margin-left: 10px;
    }
    @include media("<=414px") {
      width: 20px;
      height: 20px;
      margin-left: 5px;
      margin-bottom: 2px;
    }
  }
  @include media("<=desktop") {
    padding: 80px 0 0 47px;
    margin-bottom: 60px;
  }
  @include media(">414px", "<=tablet") {
    padding: 60px 0 0 47px;
    font-size: 46px;
    margin-bottom: 40px;
  }
  @include media("<=414px") {
    font-size: 38px;
    padding: 40px 0 0 30px;
    margin-bottom: 30px;
  }
}

.services-item {
  border: 13px solid #000000;
  margin: -6.5px;
  width: 30%;
  flex: 1 1 auto;
  @include media("<=tablet") {
    border: 10px solid #000000;
    margin: -5px;
  }
  @include media("<=450px") {
    width: 100%;
    max-width: 350px;
    margin: 0 auto;
    border: 8px solid #000000;
    border-bottom: none;
  }
  &__1 {
    background-color: #fff;
    max-width: 383px;
    margin-left: 62px;
    margin-top: 119px;
    //border-right: none;
    //border-bottom: none;
    position: relative;
    @include media("<=desktop") {
      max-width: 313px;
    }
    @include media("<=450px") {
      margin: 0 auto;
      border: 8px solid #000000;
      border-bottom: none;
      max-width: 400px;
    }
    &::before {
      content: "";
      display: inline-block;
      width: 62px;
      height: 170px;
      background-color: #3c00ff;
      position: absolute;
      left: -63px;
      bottom: -12px;
      border: 13px solid #000000;
      @include media("<=tablet") {
        border: 10px solid #000000;
        bottom: -10px;
      }
      @include media("<=450px") {
        display: none;
      }
    }
  }
  &__2 {
    background-color: #ffc600;
    max-width: 383px;
    @include media("<=desktop") {
      max-width: 310px;
    }
    @include media("<=450px") {
      max-width: 400px;
    }
  }
  &__3 {
    background-color: #3c00ff;
    color: #fff;
    max-width: 440px;
    margin-top: 70px;
    //border-left: none;
    position: relative;
    @include media("<=desktop") {
      max-width: 400px;
    }
    @include media("<=450px") {
      margin: 0 auto;
      border: 8px solid #000000;
      border-bottom: none;
    }
    &::before {
      content: "";
      display: inline-block;
      width: 124px;
      height: 70px;
      background-color: #00ccff;
      position: absolute;
      left: -12px;
      top: -70px;
      border: 13px solid #000000;
      @include media("<=tablet") {
        border: 10px solid #000000;
        left: -10px;
      }
      @include media("<=450px") {
        display: none;
      }
    }
    .item-number {
      color: #fff;
    }
  }
  &__4 {
    background-color: #00ccff;
    max-width: 451px;
    @include media("<=desktop") {
      max-width: 481px;
    }
  }
  &__5 {
    background-color: #e911a0;
    max-width: 383px;
    height: 402px;
    margin-top: -110px;
    //border-left: none;
    @include media("<=desktop") {
      max-width: 310px;
      height: 380px;
      margin-top: -125px;
    }
    @include media("<=tablet") {
      max-width: 232px;
      height: 311px;
    }
    @include media("<=450px") {
      margin: 0 auto;
      border: 8px solid #000000;
      height: auto;
      max-width: 400px;
      border-bottom: none;
    }

    .item-number {
      color: #fff;
      &::after {
        background-color: #ffc600;
      }
    }
    .item-arrow {
      border-bottom: 7px solid #000;
      border-right: 7px solid #000;
    }
  }
  &__6 {
    max-width: 370px;
    position: relative;
    //border-left: none;
    //border-top: none;
    @include media("<=desktop") {
      max-width: 315px;
      margin-right: 20px;
    }
    @include media("<=tablet") {
      max-width: 209px;
    }
    @include media("<=450px") {
      border: 8px solid #000000;
      max-width: 400px;
    }
    &::before {
      content: "";
      display: inline-block;
      width: 192px;
      height: 71px;
      background-color: #ffc600;
      position: absolute;
      left: -192px;
      bottom: -13px;
      border: 13px solid #000000;
      @include media("<=tablet") {
        border: 10px solid #000000;
        bottom: -10px;
      }
      @include media("<=450px") {
        display: none;
      }
    }
  }
}

.item-title {
  padding-top: 51px;
  padding-left: 70px;
  display: flex;
  @include media("<=desktop") {
    padding-left: 40px;
    padding-top: 30px;
  }
  @include media("<=tablet") {
    padding-left: 25px;
    padding-top: 20px;
  }

  @include media("<=450px") {
    cursor: pointer;
    padding-top: 20px;
    padding-left: 30px;
    padding-bottom: 20px;
  }
}

.item-number {
  font-family: "Mont-Black";
  color: #000;
  font-size: 66px;
  font-weight: 900;
  @include media("<=450px") {
    font-size: 40px;
  }
  @include media("<=tablet") {
    font-size: 36px;
  }
  &::after {
    content: "";
    display: inline-block;
    width: 27px;
    height: 27px;
    background-color: #e911a0;
    border-radius: 50%;
    margin-left: 8px;
    margin-bottom: 9px;
    @include media("<=tablet") {
      width: 20px;
      height: 20px;
      margin-bottom: 4px;
    }
    @include media("<=450px") {
      display: none;
    }
  }
}

.item-name {
  font-size: 27px;
  font-weight: 700;
  line-height: 40px;
  padding-left: 24px;
  @include media("<=tablet") {
    font-size: 20px;
    line-height: 20px;
    padding-left: 12px;
  }
  @include media("<=450px") {
    font-size: 18px;
    line-height: 26px;
    padding-left: 24px;
  }
}

.item-text {
  font-size: 20px;
  font-weight: 400;
  padding: 48px 90px 38px 70px;
  @include media("<=desktop") {
    padding: 30px 40px 34px 40px;
  }
  @include media("<=tablet") {
    font-size: 16px;
    padding: 30px;
  }
  @include media("<=450px") {
    font-size: 16px;
    padding: 0 20px;
    line-height: 18px;

    transition: all 600ms ease-in-out;
    max-height: 0;
    overflow: hidden;
  }
}

.services-item.is-active .item-text {
  max-height: 500px;
  padding: 20px;
}

.item-arrow {
  display: inline-block;
  transform: rotate(45deg);
  height: 20px;
  width: 20px;
  border-bottom: 7px solid #e911a0;
  border-right: 7px solid #e911a0;
  margin-left: 18px;
  margin-top: 5px;
  transition: transform 0.3s ease-in-out;
  @include media(">450px") {
    display: none;
  }
  .is-active & {
    transform: rotate(225deg);
    margin-top: 8px;
  }
}

/*ACCORDION*/
