.contacts {
  background-color: #e911a0;
}

.contacts-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 190px 80px;
  position: relative;
  overflow: hidden;
  &::after {
    content: "";
    display: block;
    position: absolute;
    background-image: url(../images/contacts-dots.png);
    width: 100%;
    max-width: 414px;
    height: 100%;
    max-height: 164px;
    background-repeat: no-repeat;
    top: 40%;
    left: 27%;
    @include media("<=tablet") {
      background-size: contain;
      max-width: 260px;
      max-height: 110px;
      top: 45%;
    }
  }
  @include media("<=desktop") {
    padding: 0 70px 50px;
  }
  @include media("<=450px") {
    padding: 0 15px 40px;
  }
}

.contacts-box {
  width: 60%;
  max-width: 522px;
  background-color: #fff;
  border: 13px solid black;
  position: relative;
  padding: 50px 0 22px 100px;
  z-index: 10;
  @include media("<=tablet") {
    border: 10px solid black;
    padding: 25px 0 12px 50px;
  }
  @include media("<=450px") {
    border: 8px solid black;
    width: 100%;
  }
}

.contacts-image {
  width: 30%;
  @include media("<=450px") {
    display: none;
  }
}

.contacts-image img {
  width: 100%;
}

.contacts-info {
  margin-bottom: 28px;
  font-size: 21px;
  a {
    color: #000;
    text-decoration: none;
    transition: color 0.3s linear;
    &:hover {
      color: #e911a0;
    }
  }
  @include media("<=tablet") {
    font-size: 18px;
    margin-bottom: 15px;
  }
  @include media("<=450px") {
    font-size: 14px;
  }
}

.mail {
  position: relative;
  &::before {
    position: absolute;
    content: "";
    left: -30px;
    top: 5px;
    width: 30px;
    height: 30px;
    background-image: url(../images/email.png);
    background-repeat: no-repeat;
    background-size: initial;
    @include media("<=450px") {
      max-width: 18px;
      height: 18px;
      background-size: contain;
      top: 3px;
    }
  }
}

.phone {
  position: relative;
  &::before {
    position: absolute;
    content: "";
    left: -30px;
    top: 3px;
    width: 30px;
    height: 30px;
    background-image: url(../images/telephone.png);
    background-repeat: no-repeat;
    background-size: initial;
    @include media("<=450px") {
      max-width: 18px;
      height: 18px;
      background-size: contain;
      top: 1px;
    }
  }
}

.address {
  position: relative;
  &::before {
    position: absolute;
    content: "";
    left: -30px;
    top: 0;
    width: 30px;
    height: 30px;
    background-image: url(../images/house-black-silhouette-without-door.png);
    background-repeat: no-repeat;
    background-size: initial;
    @include media("<=450px") {
      max-width: 18px;
      height: 18px;
      background-size: contain;
      top: -2px;
    }
  }
}
