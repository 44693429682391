.about {
  background-color: #e911a0;
  position: relative;
}

.about-background {
  position: absolute;
  display: block;
  background-image: url(../images/about-bg.png);
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  top: -65px;
  left: -80px;
  @include media("<=desktop") {
    left: 0;
  }
  @include media("<=tablet") {
    background-size: 80%;
    top: -13%;
  }
  @include media("<=667px") {
    left: 40px;
  }
  @include media("<=414px") {
    left: 0;
    background-size: 170%;
    top: -18%;
  }
}

.section-title__purple {
  padding: 149px 0 0 67px;
  margin-bottom: 80px;
  color: #fff;
  font-size: 69px;
  font-weight: 900;
  text-transform: uppercase;
  &::after {
    content: "";
    display: inline-block;
    width: 27px;
    height: 27px;
    background-color: #000000;
    border-radius: 50%;
    margin-left: 15px;
    margin-bottom: 12px;
    @include media(">414px", "<=tablet") {
      margin-bottom: 2px;
      margin-left: 10px;
    }
    @include media("<=414px") {
      width: 20px;
      height: 20px;
      margin-left: 5px;
      margin-bottom: 2px;
    }
  }
  @include media("<=desktop") {
    padding: 80px 0 0 47px;
    margin-bottom: 60px;
  }
  @include media(">414px", "<=tablet") {
    padding: 60px 0 0 47px;
    font-size: 46px;
    margin-bottom: 40px;
  }
  @include media("<=414px") {
    font-size: 38px;
    padding: 40px 0 30px 30px;
    margin-bottom: 30px;
  }
}

.about-inner {
  display: flex;
  justify-content: space-between;
  padding: 0 67px 115px;
  position: relative;
  z-index: 10;
  @include media("<=desktop") {
    padding: 0 47px 85px;
  }
  @include media("<=tablet") {
    padding-bottom: 65px;
  }
  @include media("<=450px") {
    flex-direction: column-reverse;
    padding: 0 15px 40px;
  }
}

.about-image {
  margin-top: -16px;
  @include media("<=desktop") {
    margin-right: 30px;
  }
  @include media("<=450px") {
    margin: -20px auto 0;
    position: relative;
    z-index: 10;
    padding: 0 20px;
  }
  img {
    width: 100%;
  }
}

.about-text {
  width: 100%;
  max-width: 572px;
  border: 13px solid #000000;
  background-color: #ffffff;
  text-align: left;
  font-family: Roboto;
  font-size: 24px;
  font-weight: 400;
  line-height: 34.79px;
  padding: 64px 84px 20px 73px;
  position: relative;
  @include media("<=desktop") {
    max-width: 470px;
    padding: 44px 64px 20px 63px;
    font-size: 20px;
  }
  @include media("<=tablet") {
    max-width: 340px;
    padding: 24px 34px 20px;
    font-size: 18px;
    line-height: 26px;
    border: 10px solid #000000;
  }
  @include media("<=667px") {
    max-width: 300px;
    padding: 20px 30px 20px;
    font-size: 16px;
    line-height: 22px;
  }
  @include media("<=450px") {
    border: 8px solid #000000;
    margin: 0 auto;
    padding: 20px;
    max-width: 350px;
  }
  span {
    font-weight: 700;
  }
  &::before {
    content: "";
    position: absolute;
    display: block;
    width: 39px;
    height: 39px;
    background-color: #fff;
    border-radius: 50%;
    top: -70px;
    right: 60px;
    @include media("<=450px") {
      display: none;
    }
  }
  &::after {
    content: "";
    position: absolute;
    display: block;
    width: 39px;
    height: 39px;
    background-color: #000;
    border-radius: 50%;
    bottom: 10px;
    left: -70px;
    @include media("<=450px") {
      display: none;
    }
  }
}
