body {
  margin: 0;
  font-family: "Roboto";
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  margin: 0 0 10px;
}

/*CONTAINER*/
.container {
  width: 100%;
  max-width: 1276px;
  margin: 0 auto;
}

//BURGER BUTTON//
.burger-button {
  display: none;

  @include media("<=tablet") {
    display: block;
    position: fixed;
    top: 45px;
    right: 40px;
    z-index: 200;
  }

  @include media("<=736px") {
    top: 25px;
    right: 20px;
  }

  @include media("<=tablet") {
    &.active {
      position: fixed;
      top: 45px;
      right: 40px;
      z-index: 200;
    }
    @include media("<=736px") {
      &.active {
        top: 25px;
        right: 20px;
      }
    }
  }

  .line {
    height: 10px;
    width: 56px;
    margin-bottom: 8px;
    background: #000;
    transition: all 200ms ease-in-out;
    opacity: 1;

    @include media("<=736px") {
      height: 8px;
      width: 48px;
      margin-bottom: 6px;
    }
  }

  &.active {
    .line {
      &.one {
        transform: rotate(45deg) scale(1.2);
      }
      &.two {
        transform: rotate(-45deg) scale(1.2) translateY(-10px) translateX(10px);
        @include media("<=736px") {
          transform: rotate(-45deg) scale(1.2) translateY(-8px) translateX(8px);
        }
      }
      &.three {
        opacity: 0;
      }
    }
  }
}

/* Progress bar */
.progress-container {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1;
  width: 100%;
}

/* Hero */
.hero {
  overflow: hidden;
}
