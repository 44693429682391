@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Black.eot");
  src: local("☺"), url("../fonts/Roboto-Black.woff") format("woff"),
    url("../fonts/Roboto-Black.ttf") format("truetype"),
    url("../fonts/Roboto-Black.svg") format("svg");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Italic.eot");
  src: local("☺"), url("../fonts/Roboto-Italic.woff") format("woff"),
    url("../fonts/Roboto-Italic.ttf") format("truetype"),
    url("../fonts/Roboto-Italic.svg") format("svg");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Regular.eot");
  src: local("☺"), url("../fonts/Roboto-Regular.woff") format("woff"),
    url("../fonts/Roboto-Regular.ttf") format("truetype"),
    url("../fonts/Roboto-Regular.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Mont-Black";
  src: url("../fonts/Mont-Black.eot");
  src: local("☺"), url("../fonts/Mont-Black.woff") format("woff"),
    url("../fonts/Mont-Black.ttf") format("truetype"),
    url("../fonts/Mont-Black.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
